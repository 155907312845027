'use client';

import '@rainbow-me/rainbowkit/styles.css';
import { BalanceProvider } from './_common/ui/balance-provider/balance-provider';
import { PriceProvider } from './_common/ui/price-provider/price-provider/price-provider';
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  return <PriceProvider data-sentry-element="PriceProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <BalanceProvider data-sentry-element="BalanceProvider" data-sentry-source-file="providers.tsx">{children}</BalanceProvider>
    </PriceProvider>;
}